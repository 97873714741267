export const orderStatuses = {
	inprogress: "Nouvelle",
	delivered: "Livré",
	canceled: "Annulé"
}
export const deliveryPrice = 30
export const pricesMap = new Map()
pricesMap.set("Casablanca", 300)
pricesMap.set("Rabat", 170)
pricesMap.set("Casablanca + Rabat", 470)
